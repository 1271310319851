@use "@angular/material" as mat;

@include mat.core();
/* You can add global styles to this file, and also import other style files */
body {
  background-color: #fcfcfc;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Rubik, "Helvetica Neue", sans-serif;
}
.mat-focused {
  outline: none !important;
  border-style: none !important;
}

.mdc-text-field__input {
  color: #484a4c !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  align-items: center;
  letter-spacing: 0.0025em;
}
.mdc-text-field__input::placeholder {
  color: #d9d9d9 !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  align-items: center;
  letter-spacing: 0.0025em;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mat-mdc-select-placeholder {
  color: #d9d9d9 !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.mat-mdc-select-value {
  color: #484a4c !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
}

.hint {
  color: #c9c9c9 !important;
}
